import React from 'react';
import BlogArticle from '../../../components/common/BlogArticle';
import SEO from '../../../components/SEO';

import { useScrollToTop } from '../../../hooks/useScrollToTop';
import blogImage from '../../../assets/img/blog/blog-4.png';

import './BlogArticlePage.scss';

interface BlogData {
    id: number;
    title?: string;
    text: string;
    type: string;
    subtype: string;
}

interface BlogItems extends Array<BlogData> {}

const BlogArticlePage = () => {
    const contentData = [
        {
            id: 1,
            text: 'Collaborative Partnerships: Fostering Success Together',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 2,
            text: 'Tailor Solutions to Your Unique Needs',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 3,
            text: 'Harnessing Cutting-Edge Technologies: Powering Digital Transformation',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 4,
            text: 'Transparent Communication: Nurturing Trust and Collaboration',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 5,
            text: 'Agile Project Management: Delivering Excellence with Flexibility',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 6,
            text: 'Cultivating a Culture of Innovation: Driving Future Success',
            type: 'margin',
            subtype: 'blog',
        },
        {
            id: 7,
            text: 'Conclusion',
            type: 'margin',
            subtype: 'blog',
        },
    ];

    const blogData: BlogItems = [
        {
            id: 0,
            text:
                "At Blackbird Lab, we have been driving success for the past 5 years for our clients in Canada. For us, co-innovation is not just a buzzword—it's a powerful mindset that can propel businesses toward new horizons. Let's dive in and explore how co-innovation can be a game-changer for your outsourcing endeavors!",
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 1,
            title: 'Collaborative Partnerships: Fostering Success Together',
            text:
                'At Blackbird Lab, we understand that success lies in forging collaborative partnerships with our clients. Working together as a unified team can harness collective expertise and insights to drive innovation and achieve remarkable results. With a global presence and a dedicated focus on understanding Canadian businesses, we bring a unique perspective to co-innovation. Our offices in Ukraine and Colombia enable us to tap into diverse talent pools and offer a comprehensive understanding of the Canadian market, ensuring we deliver solutions that align with your needs. Embrace this mindset and seek vendors who are service providers and true partners invested in your success.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 2,
            title: 'Tailor Solutions to Your Unique Needs',
            text:
                'We firmly believe that one size does not fit posts. Every business possesses unique characteristics and challenges. Blackbird Lab excels in crafting tailored solutions that unlock your full potential. Our co-innovation model ensures that we design customized strategies that address your specific objectives, industry landscape, and market dynamics, giving you a competitive edge in the Canadian market and beyond.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 3,
            title: 'Harnessing Cutting-Edge Technologies: Powering Digital Transformation',
            text:
                'Software landscape is constantly evolving, with new technologies emerging at a rapid pace. At Blackbird Lab, we stay at the forefront of innovation, leveraging cutting-edge technologies to drive digital transformation for our clients. With our finger on the pulse of Canadian businesses, we identify and deploy advanced technologies specifically relevant to your industry, amplifying your digital transformation journey with breakthrough solutions. This ensures that your business remains competitive in the ever-changing digital landscape.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 4,
            title: 'Transparent Communication: Nurturing Trust and Collaboration',
            text:
                'Effective communication is the bedrock of successful co-innovation partnerships. Establish open communication channels with your technology partner, fostering an environment where ideas, feedback, and concerns can be freely shared. This enables us to engage in meaningful conversations, align our strategies, and work together seamlessly to achieve shared goals.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 5,
            title: 'Agile Project Management: Delivering Excellence with Flexibility',
            text:
                'Agility is a core tenet of our project management approach. Blackbird Lab embraces Agile methodologies, allowing us to adapt swiftly to evolving requirements and deliver outstanding results quickly and precisely. Our global presence, complemented by our understanding of Canadian businesses, equips us to navigate market nuances and ensure smooth project execution, enabling you to meet critical deadlines and exceed customer expectations.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 6,
            title: 'Cultivating a Culture of Innovation: Driving Future Success',
            text:
                'Innovation is a journey, not a destination, and our driving force. Encourage a culture of continuous learning and improvement within your organization and with your outsourcing partner. Seek a partner like Blackbird Lab that nurtures continuous learning, improvement, and innovation and is empowered to bring fresh ideas, emerging technologies, and best practices. Together, we can drive ongoing success and stay ahead of the competition.',
            type: 'mb',
            subtype: 'blog',
        },
        {
            id: 6,
            title: 'Conclusion',
            text:
                "We can unlock the full potential by embracing collaborative partnerships, tailoring solutions, leveraging cutting-edge technologies, fostering open communication, adopting agile project management, and continuously evolving. At Blackbird Lab, we have been driving success for the past 5 years for our clients in Canada. For us, co-innovation is not just a buzzword—it's a powerful mindset that can propel businesses toward new horizons. If you're ready to embark on a transformative journey, reach out to Blackbird Lab today. Let's shape a brighter future where your success knows no boundaries!",
            type: 'mb',
            subtype: 'blog',
        },
    ];

    useScrollToTop();

    return (
        <div>
            <SEO
                pageTitle="Blackbird Lab - Blog"
                pageDescription="Co-Innovation in Action: Achieving Remarkable Results"
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/blog/articles/co-innovation-in-action.png"
            />
            <BlogArticle
                title="Co-Innovation in Action: Achieving Remarkable Results"
                image={blogImage}
                author="Yurii Drozd"
                date="July 28, 2023"
                blogData={blogData}
                contentData={contentData}
            />
        </div>
    );
};

export default BlogArticlePage;
